<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="createUserBool"
        transition="dialog-bottom-transition"
        max-width="600"
        persistent
        :dark="$store.getters.getNightMode"
        style="z-index: 9"
      >
        <template>
          <v-card class="border-0">
            <v-toolbar
              color="primary"
              :class="[
                $store.getters.getNightMode ? 'text-dark' : '!tw-text-white',
                'mb-10',
              ]"
              >{{ DialogTitle }} User Dialog</v-toolbar
            >
            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-account"
                        v-model="user.firstName"
                        @keypress="($event) => nameKeydown($event)"
                        :counter="20"
                        label="First name *"
                        required
                        dense
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-account"
                        v-model="user.lastName"
                        @keypress="($event) => nameKeydown($event)"
                        :counter="20"
                        label="Last name *"
                        required
                        dense
                      />
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="user.username"
                        label="Email *"
                        required
                        dense
                        prepend-icon="mdi-email"
                        placeholder="Eg: example@email.com"
                      />
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="12">
                      <v-select
                        v-model="user.roleUuids"
                        item-value="uuid"
                        item-text="roleName"
                        prepend-icon="mdi-crown"
                        :items="designations"
                        label="Roles"
                        multiple
                        dense
                      />
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="12">
                      <v-text-field
                        v-model="user.password1"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        label="Password *"
                        required
                        dense
                        prepend-icon="mdi-lock"
                        hint="At least 8 characters"
                        :rules="[rules.required, rules.min]"
                        @click:append="show1 = !show1"
                      />
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="12">
                      <v-text-field
                        v-model="user.password2"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        label="Confirm Password *"
                        required
                        dense
                        prepend-icon="mdi-lock"
                        hint="At least 8 characters"
                        :rules="[rules.required, rules.min]"
                        @click:append="show2 = !show2"
                      />
                    </v-col>
                    <v-col cols="12" md="12">
                      <span v-if="user.password2.length>0|| user.password1.length>0">
                        <p v-if="user.password1==user.password2" style="color:green"><v-icon color="success">mdi-check-circle</v-icon> Password Matched</p>
                      <p v-else style="color:red"><v-icon color="error">mdi-close-circle</v-icon> Password Not Matched</p>
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <span v-if="user.password2.length>0|| user.password1.length>0 || DialogTitle=='Edit'">
                <v-btn
                  color="success"
                  elevation="0"
                  class="rounded-lg"
                  dense
                  text
                  v-if="user.password1 === user.password2"
                  @click="createUpdateUser()"

                ><v-icon small>mdi-check-circle</v-icon> Save</v-btn
              ></span>
              <v-btn
                color="error"
                elevation="0"
                class="rounded-lg"
                dense
                text
                @click="$emit('close')"
                ><v-icon small>mdi-close</v-icon> Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
// import { omit } from "lodash";
import getUserRoles from "@/GraphQL/UserManagement/getUserRoles.graphql"
export default {
  name: "CreateUser",
  data() {
    return {
      valid: false,
      show1: false,
      show2: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      user: {
        id: null,
        firstName: "",
        lastName: "",
        username: "",
        password1: "",
        password2: "",
        roleUuids:[],
      },
      createUserBool: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
      ],
      email: "",
    };
  },
  props: ["CreateUserBool", "EditItemData", "DialogTitle"],
  watch: {
    CreateUserBool(newValue) {
      this.createUserBool = newValue;
    },
    EditItemData(newValues) {
      this.user.id = Object.hasOwn(newValues, "id") ? newValues.id : "";
      this.user.username = Object.hasOwn(newValues, "email") ? newValues.email : "";
      this.user.firstName = Object.hasOwn(newValues, "firstName")? newValues.firstName : "";
      this.user.lastName = Object.hasOwn(newValues, "lastName")? newValues.lastName : "";
      if(this.user.id){
        this.$store.commit("setLoadingState", true);
        this.$apollo.query({
          query: getUserRoles,
          variables: { id: this.user.id }
        }).then((response) => {
          let roles=[]
          response.data.getUserRoles[0].role.forEach(element=>{
            roles.push(element.uuid)
          })
          this.user.roleUuids = response.data.getUserRoles[0].role ? roles : "";
          // console.log(response.data.getUserRoles[0].role);
        }).finally(()=>{
          this.$store.commit("setLoadingState", false);
        });

      }

    },
  },
  computed: {
    departments() {
      return this.$store.getters.getDepartments;
    },
    designations() {
      return this.$store.getters.getRoles;
    },
  },

  mounted() {
    // this.$store.dispatch("loadInstitutionDepartments");
    // this.$store.dispatch("loadInstitutionDesignations");
  },
  methods: {
    async createUpdateUser() {
      let data = {
        username: this.user.username,
        email: this.user.username,
        firstName: this.user.firstName,
        password1: this.user.password1,
        password2: this.user.password2,
        lastName: this.user.lastName,
        roleUuids: this.user.roleUuids
      };
      // if (Object.hasOwn(this.user, "uuid")) {
      //   if (this.user.uuid.length == 0) {
      //     delete this.user.uuid;
      //   }
      // }
      // if (
      //   Object.hasOwn(this.user, "firstName") &&
      //   Object.hasOwn(this.user, "lastName")
      // ) {
      //   this.user.firstName = `${this.user.firstName} ${this.user.lastName}`;
      //   // omit(this.user, ["firstName", "lastName"]);
      // }
      // Object.entries(this.user).forEach(([key, value]) => {
      //   if (value != "" || value != null || value != undefined) {
      //     if (!["firstName", "lastName"].includes(key)) {
      //       data[key] = value;
      //     }
      //   }
      // });
      // console.log(data)

      this.user.id?this.$store.dispatch('updateUser',data).then(() => {this.$emit("close");}):
      await this.$store.dispatch("register", data).then(() => {this.$emit("close");});
    },
    removeSpecialCharacters(charactersString) {
      return charactersString.replace(/[^\w\s]/gi, "");
    },
    checkForSpecialCharactersAndSpaces(charactersString) {
      // const specialCharactersRegex = /[^\w\s]/;
      const specialCharactersAndSpacesRegex =
        /[ `!@#$%^&*()+-\\=[\]{};':"\\|,.<>/?~]/;
      return specialCharactersAndSpacesRegex.test(charactersString);
    },
    nameKeydown(e) {
      if (e.keyCode == 8 || (e.keyCode >= 65 && e.keyCode <= 90)) {
        //backspace
        return true;
      }
      if (this.checkForSpecialCharactersAndSpaces(e.key)) {
        e.preventDefault();
      }
    },
    phoneKeydown(e) {
      var charCode = e.which ? e.which : e.keyCode;
      if (charCode == 8) {
        //backspace
        return true;
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        e.preventDefault();
      }
      if (e.target.value.length > 11) {
        e.preventDefault();
      } else {
        e.target.value.replace(/\D/g, "");
        return true;
      }
    },
  },
};
</script>
