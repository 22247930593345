<template>
  <v-card class="mt-5 mx-10">
    <CreateUser
        :CreateUserBool="createUserBool"
        :DialogTitle="dialogTitle"
        :EditItemData="editItemData"
        @close="() => closeCreateUpdateUserDialog()"
      />
    <v-card-title class="d-flex align-center">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        filled
        rounded
        class="rounded-lg"
        dense
        small
      />
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="openCreateUserDialog()"
        elevation="0"
        class="rounded-lg mb-4"
      >
        <v-icon small>mdi-plus</v-icon>&nbsp;Create User
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5 tw-text-slate-600"
            >Are you sure you want to delete {{ this.user.username }}?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue" class="text-capitalize" outlined @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="red" class="text-capitalize" outlined @click="deleteItemConfirm"
              ><v-icon small>mdi-delete</v-icon>Delete</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table :headers="headers" :items="users" :search="search">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Users</v-toolbar-title>
          </v-toolbar>
        </template>
        <template v-slot:item.sn="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CreateUser from "@/components/UserManagement/CreateUser.vue";

export default {
  name: "UserTable",
  components: { CreateUser },

  data: () => ({
    search: "",
    dialogTitle: null,
    editItemData: null,
    createUserBool: false,
    dialogDelete: false,
    user: {},
    headers: [
      {
        text: "S/N",
        align: "start",
        filterable: false,
        value: "sn",
      },
      { text: "First Name", value: "firstName" },
      { text: "Last Name", value: "lastName" },
      { text: "Email", value: "email" },
    //   { text: "Phone", value: "phoneNumber" },
    //   { text: "Designation", value: "designation.shortForm" },
    //   { text: "Department", value: "department.departmentCode" },
      { text: "Actions", filterable: false, value: "actions" },
    ],
  }),

  computed: {
    users() {
      return this.$store.getters.getUsers;
    },
    departments() {
      return this.$store.getters.getDepartments;
    },
  },
  mounted() {
    this.initialize();
    // this.$store.dispatch("loadUsers");

  },

  methods: {
    async initialize() {
      // this.$store.commit("setLoadingState", true);
      await this.$store.dispatch("loadRoles");
      await this.$store.dispatch("loadUsers");
      // .then(() => this.$store.commit("setLoadingState", false));
    },
    editItem(item) {
      // console.log(item)
      this.editItemData = item;
      this.createUserBool = true;
      this.dialogTitle = "Edit";
    },
    openCreateUserDialog() {
      this.editItemData = {};
      this.createUserBool = true;
      this.dialogTitle = "Create";
    },
    closeCreateUpdateUserDialog() {
      this.$store.dispatch("loadUsers");
      this.createUserBool = false;
      let data = {
        uuid: null,
        fullName: "",
        firstName: "",
        lastName: "",
        username: "",
      };
      this.editItemData = data;
    },
    deleteItem(item) {
      this.user = {
        id: item.id,
        username: item.firstName+" "+item.lastName,
      };
      this.dialogDelete = true;
      // console.log(this.user);
    },

    deleteItemConfirm() {
      // console.log(this.user)
      this.$store
        .dispatch("deleteUser", { id: this.user.id })
        .then(() => {
          this.user = {};
          this.closeDelete();
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.user = {};
      });
    },
  },
};
</script>

<style scoped></style>
